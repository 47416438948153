import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const PersonCard = ({
  email,
  firstName,
  image,
  jobTitle,
  lastName,
  path,
  phone
}) => 
  <Link className='block hover:scale-up-5 transition-transform' to={path}>
    <img
      className='h-full mb-3 w-full'
      loading='lazy'
      src={image}
      alt={firstName}
      title={firstName}
    />
    <h2 className='border-b border-primary pb-1 mb-3 tracking-widest uppercase'>
      <span className='truncate'>{firstName}</span>&nbsp;
      <span className='truncate'>{lastName}</span>
    </h2>
    <p className='font-medium italic mb-3 pr-4 text-gray-900'>{jobTitle}</p>
    <div className='flex items-center mb-1'>
      <svg viewBox='0 0 24 24' width='16' height='16'>
        <path
          className='fill-current'
          d='M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z'
        />
      </svg>
      <button
        className='break-all focus:outline-none ml-2 text-left hover:underline'
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          window.location.href = `mailto:${email}`;
        }}
      >
        {email}
      </button>
    </div>
    {phone && 
      <div className='flex items-center'>
        <svg viewBox='0 0 24 24' width='16' height='16'>
          <path
            className='fill-current'
            d='M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z'
          />
        </svg>
        <button
          className='break-all focus:outline-none ml-2 text-left hover:underline'
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            window.location.href = `tel:${phone}`;
          }}
        >
          {phone}
        </button>
      </div>
    }
  </Link>;


PersonCard.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  phone: PropTypes.string
};

export default PersonCard;
