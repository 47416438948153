import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const ServiceCard = ({ image, path, title }) => 
  <Link className='block hover:shadow-lg relative transition-shadow' to={path}>
    <img
      className='h-full w-full'
      loading='lazy'
      src={image}
      alt={title}
      title={title}
    />
    <div className='absolute flex inset-0 items-center pin p-6'>
      <h2 className='bg-white p-2 shadow-md text-center tracking-widest break-words w-full uppercase'>
        {title}
      </h2>
    </div>
  </Link>;


ServiceCard.propTypes = {
  image: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ServiceCard;
