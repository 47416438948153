import { Link, graphql } from "gatsby";
import Page from "../components/page";
import PersonCard from "../components/person-card";
import PropTypes from "prop-types";
import React from "react";
import SEO from "../components/seo";
import ServiceCard from "../components/service-card";

const IndexPage = ({ data }) => {
  let delay = 0;
  const serviceCards = data.services.edges.map(service => {
    delay += 50;
    return (
      <div
        key={service.node.id}
        className='mb-4 sm:mb-0 sm:-mx-2 sm:px-2 sm:py-3 lg:py-0 sm:w-1/2 lg:w-1/4'
        data-aos='fade-up'
        data-aos-delay={delay}
      >
        <ServiceCard
          image={service.node.image.fluid.src}
          path={`/aanbod/${service.node.name}`.toLowerCase().replace(/ /g, "-")}
          title={service.node.name}
        />
      </div>
    );
  });

  delay = 0;
  const teamCards = data.employees.edges.map(employee => {
    delay += 50;
    return (
      <div
        key={employee.node.id}
        className='mb-10 sm:mb-0 sm:-mx-2 sm:px-2 sm:py-3 lg:py-0 sm:w-1/2 lg:w-1/4'
        data-aos='fade-up'
        data-aos-delay={delay}
      >
        <PersonCard
          email={employee.node.email}
          firstName={employee.node.firstName}
          image={employee.node.profileImage.fluid.src}
          jobTitle={employee.node.jobTitle}
          lastName={employee.node.lastName}
          path={`/team/${employee.node.firstName}-${employee.node.lastName}`
            .toLowerCase()
            .replace(/ /g, "-")}
          phone={employee.node.phone}
        />
      </div>
    );
  });

  return (
    <Page>
      <SEO title='Home' />
      <div
        className='bg-cover bg-no-repeat container px-8 lg:px-0 lg:pl-16 py-8 md:py-20 lg:pt-24 lg:pb-40 w-full'
        data-aos='fade-up'
        style={{ backgroundImage: `url(${data.hero.file.url})` }}
      >
        <h1
          className='font-bold mb-4 text-3xl md:text-5xl text-shadow text-black'
          data-aos='fade-up'
        >
          Welkom in onze praktijk
        </h1>
        <p
          className='font-bold text-sm text-shadow text-black md:w-1/2 xl:w-1/3'
          data-aos='fade-up'
        >
          Praktijkhuis Integraal is een{" "}
          <span className='italic text-green-700'>
            multidisciplinaire groepspraktijk
          </span>{" "}
          waar ervaren psychotherapeuten, psychologen, coaches en
          lichaamsgerichte therapeuten samenwerken. Een integrale aanpak is ons
          kenmerk en wij hechten veel belang aan onderlinge samenwerking en met
          externe zorgverleners.
          <br />
          <br />
          Wij bieden{" "}
          <span className='italic text-green-700'>professionele zorg</span> en
          begeleiding aan iedereen bij kwetsbare momenten in het leven. Iedere
          medewerker heeft aandacht voor de mens in zijn geheel.
        </p>
      </div>
      <div className='p-8 lg:py-6 lg:mb-20'>
        <div className='container sm:flex sm:flex-wrap justify-between'>
          {serviceCards}
        </div>
      </div>
      <p
        className='italic mb-4 md:mb-8 md:mt-8 lg:mb-20 px-8 text-gray-800 text-2xl md:text-4xl text-center'
        data-aos='fade-up'
      >
        ‘Praktijkhuis Integraal richt zich op jongeren
        <br className='hidden md:block' /> en volwassenen, individuen en
        koppels.’
      </p>
      <div className='p-8 mb-8 lg:mb-20' data-aos='fade-up'>
        <div className='container sm:flex sm:flex-wrap justify-between'>
          {teamCards}
        </div>
        <div className='container mt-6 lg:mt-10 text-center'>
          <Link
            className='italic text-secondary hover:text-blue-600 text-xl transition-color underline'
            to='/team'
          >
            Ontdek het volledige team
          </Link>
        </div>
      </div>
    </Page>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    employees: allContentfulEmployee(
      limit: 4
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          id
          firstName
          lastName
          email
          phone
          jobTitle
          profileImage {
            fluid {
              src
            }
          }
        }
      }
    }
    services: allContentfulService(
      limit: 4
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          id
          name
          image {
            fluid {
              src
            }
          }
        }
      }
    }
    hero: contentfulAsset(contentful_id: { eq: "3mSFUTIDlPhNX42W65oUOk" }) {
      file {
        url
      }
    }
  }
`;
